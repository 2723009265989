jQuery(document).ready(function(){
    function resizeSvgBlock(){
        let svgBlock = jQuery('.svgResize'),
        svgHeight = svgBlock.height(),
        svgWidth = svgBlock.width(),
        parentBlock = svgBlock.closest('DIV'),
        parentWidth = parentBlock.width(),
        k = svgWidth / svgHeight,
        left = 0;
        // console.log(svgHeight);
        // console.log(svgWidth);
        // console.log(parentWidth);
        // console.log(k);
        left = (parentWidth - svgWidth) / 2;
        // console.log(left);
        svgBlock.css('left', left+'px');
    }
    resizeSvgBlock();

    function resizeShieldBlock(){
        let listObj = jQuery('.resizeShieldBlock');
        // console.log(listObj);
        if(typeof listObj != "undefined" && listObj.length > 0){
            jQuery.each(listObj, function(ind, val){
                // console.log('shield: '+ind);
                let thisObj = jQuery(val),
                shieldBlock = thisObj.closest('.shields'),
                pointTop = thisObj.data('pos-top'),
                pointLeft = thisObj.data('pos-left'),
                inheritHeight = thisObj.data('inherit-height'),
                inheritWidth = thisObj.data('inherit-width'),
                blockHeight = shieldBlock.height(),
                blockWidth = shieldBlock.width(),
                kH = blockHeight / inheritHeight,
                kW = blockWidth / inheritWidth,
                top = pointTop * kH,
                left = pointLeft * kW;
                // console.log(pointTop, pointLeft);
                // console.log(blockHeight, blockWidth);
                // console.log(inheritHeight, inheritWidth);
                // console.log(kH, kW);
                // console.log(top, left);
                if(typeof top != "undefined" && top > 0){
                    if(typeof left != "undefined" && left > 0){
                        thisObj.css({top: top+'px', left: left+'px'})
                    }
                }
                // console.log(k);
                // left = (parentWidth - svgWidth) / 2;
                // console.log(left);
                // svgBlock.css('left', left+'px');
            });
        }
    }
    resizeShieldBlock();

    function resizeModule(){
        resizeSvgBlock();
        resizeShieldBlock();
    }

    // preloader
    function preloaderHide(){
        setTimeout(() => {
            jQuery('.blockHouse .visual-selection__preload.active').animate(
                { opacity: 0 }, 
                1500, 
                function(){ $( this ).removeClass('active').css({display: 'none'}) }
            )
        }, 300);
    }
    preloaderHide();

    jQuery(window).resize(function(){
        resizeModule();
    });

    jQuery('.r-visual-shield').on('mouseenter', function(){
        let wrapBlock = jQuery(this).closest('.wrap'),
            target = jQuery(this).data('sel'),
            wbs = wrapBlock.find('.svg .visual-polygon#'+target);
        // console.log('r-visual-shield: '+target);
        if(typeof wbs != "undefined" && wbs.length > 0){
            wbs.addClass('hover');
        } else {
            wrapBlock.find('.svg .visual-polygon').addClass('hover');
        }
    }).on('mouseleave', function(){
        let wrapBlock = jQuery(this).closest('.wrap');
        wrapBlock.find('.svg .visual-polygon.hover').removeClass('hover');
    })

    jQuery('.visual-shield-infra').on('mouseenter', function(){
        let wrapBlock = jQuery(this).closest('.wrap'),
            target = jQuery(this).data('sel'),
            wbs = wrapBlock.find('.svg .visual-polygon:has(#'+target+')');
        if(typeof wbs != "undefined" && wbs.length > 0){
            wbs.addClass('hover');
        } else {
            wrapBlock.find('.svg .visual-polygon').addClass('hover');
        }
    }).on('mouseleave', function(){
        let wrapBlock = jQuery(this).closest('.wrap');
        wrapBlock.find('.svg .visual-polygon.hover').removeClass('hover');
    })

    jQuery('.visual-selection__infra').on('click', function(){
        let thisObj = jQuery(this),
        rSwitch = thisObj.find('.r-switch'),
        rSwitchActive = typeof rSwitch != "undefined" && rSwitch.hasClass('_active'),
        wrapper = thisObj.closest('.closestBlockGPLayers'),
        shields = wrapper.find('.shields .visual-shield-infra')
        if(rSwitchActive){
            rSwitch.removeClass('_active');
            shields.hide(300);
        } else {
            rSwitch.addClass('_active');
            shields.show(300);
        }
    })

    function flatZoom(wrapBlock, isZoom){
        let frameBlock = wrapBlock.find('.visual-selection-flats__wrap'),
            transformMatrix = frameBlock.css('transform'),
            transform = transformMatrix.substring(transformMatrix.indexOf('(') + 1, transformMatrix.indexOf(')')).split(','),
            indexZoom = 0.2;
        // console.log(transform);
        transform = parseFloat(transform);
        if(transform <= 0) transform = 1;
        isZoom ? transform += indexZoom : transform -= indexZoom;
        frameBlock.css('transform', 'scale('+transform+')');
        if(transform > 1){
            wrapBlock.find('.r-zoom-controls__btn-zoom._out').attr('disabled', false);
        }
        if(transform <= 1){
            wrapBlock.find('.r-zoom-controls__btn-zoom._out').attr('disabled', 'disabled');
        }
        if(transform >= 2){
            wrapBlock.find('.r-zoom-controls__btn-zoom._in').attr('disabled', 'disabled');
        }
        if(transform <= 2){
            wrapBlock.find('.r-zoom-controls__btn-zoom._in').attr('disabled', false);
        }
    }
    jQuery('.visual-selection-flats__zoom ._in, .visual-selection-flats__zoom ._out').on('click', function(){
        let thisObj = jQuery(this),
            isZoom = thisObj.hasClass('_in'),
            wrapBlock = thisObj.closest('.wrap');
        // console.log(transform);
        flatZoom(wrapBlock, isZoom);
    })



    //---- in module child
    // get in
    jQuery('BODY').on('click', '.getInApartment', function(){
        let thisObj = jQuery(this),
            objLoading = thisObj.closest('.wrapperVisualScheme'),
            idCat = thisObj.data('category-child'),
            idRoom = thisObj.data('room-child'),
            post = JSON.stringify({});
        console.log('getInApartment');
        if((typeof idCat != "undefined" && idCat > 0) || (typeof idRoom != "undefined" && idRoom > 0)){
            let url = '';
            if(typeof idRoom != "undefined" && idRoom > 0){
                url = '/api/apartment/room/' + idRoom;
            } else if(typeof idCat != "undefined" && idCat > 0){
                url = '/api/apartment/category/' + idCat;
            }
            console.log('Пшёл на '+url);
            jQuery('.blockHouse .visual-selection__preload').addClass('active').css({'display': 'block', 'opacity': '1'});
            jQuery.ajax({
                url    : url,
                type   : 'POST',
                //dataType : 'json',
                data : {data: post},
                headers: { 'X-CSRF-Token': thisObj.find('INPUT[name=_token]').val() },
                cache  : false,
                //success: function(data){}
            })
            .done( function(data){
                    console.log(data);
                    //data = JSON.parse(data);
                    if( data.status ){
                        if( data.data.template ){
                            // console.log(data.data.template);
                            // window.getAlert('success', data.message, '', false);
                            objLoading.html(data.data.template);
                        } else { 
                            // window.getAlert('error', 'Не получили шаблон для загрузки ', data.message);
                            if(data.data.url) window.location.href = data.data.url;
                        }
                    } else { window.getAlert('error', 'Ошибка на сервере', data.message); }
                }
            )
            .fail(function(data){
                console.log(data);
                //alert('Error');
                }
            )
            .always(function(){
                resizeModule();
                preloaderHide();
            });
        }
    });
    // get out
    jQuery('BODY').on('click', '.visualSchemeHeaderPalette .backLink', function(){
        let thisObj = jQuery(this),
            objLoading = thisObj.closest('.wrapperVisualScheme'),
            idCat = thisObj.data('category-id'),
            post = JSON.stringify({});
        console.log('backLink');
        if(typeof idCat != "undefined" && idCat > 0){
            let url = '/api/apartment/category/' + idCat;
            console.log('Пшёл на '+url);
            jQuery('.blockHouse .visual-selection__preload').addClass('active').css({'display': 'block', 'opacity': '1'});
            jQuery.ajax({
                url    : url,
                type   : 'POST',
                //dataType : 'json',
                data : {data: post},
                headers: { 'X-CSRF-Token': thisObj.find('INPUT[name=_token]').val() },
                cache  : false,
                //success: function(data){}
            })
            .done( function(data){
                    console.log(data);
                    //data = JSON.parse(data);
                    if( data.status ){
                        if( data.data.template ){
                            // console.log(data.data.template);
                            // window.getAlert('success', data.message, '', false);
                            objLoading.html(data.data.template);
                        } else { 
                            // window.getAlert('error', 'Не получили шаблон для загрузки ', data.message);
                            if(data.data.url) window.location.href = data.data.url;
                        }
                    } else { window.getAlert('error', 'Ошибка на сервере', data.message); }
                }
            )
            .fail(function(data){
                console.log(data);
                //alert('Error');
                }
            )
            .always(function(){
                resizeModule();
                preloaderHide();
            });
        }
    });




    jQuery(window).resize(function(){
        // reSizeEditor()
    })

});